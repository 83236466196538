import React, { useEffect } from "react";
import Hero from "../component/Home/Hero";
// import Prestation from "../component/Home/Prestation";

function Home() {



  useEffect (() => {
    document.title = "Bienvenue chez Impro Café | Impro Café";
  }, []);

  // const prestations = [
  //   {
  //     image: "images/prestations/team-building.png",
  //     title: "Team Building",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  //   {
  //     image: "images/prestations/parler-en-public.png",
  //     title: "Parler en public",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  //   {
  //     image: "images/prestations/lacher-prise.png",
  //     title: "Lâcher Prise",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  //   {
  //     image: "images/prestations/cohesion.png",
  //     title: "Cohésion",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  //   {
  //     image: "images/prestations/lacher-prise.png",
  //     title: "Lâcher Prise",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  //   {
  //     image: "images/prestations/cohesion.png",
  //     title: "Cohésion",
  //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti asperiores incidunt quidem dolorem sed, odio inventore nesciunt vero. Numquam sit provident unde minus expedita modi consectetur magni blanditiis ipsa cum!"
  //   },
  // ]


  


  return (
    <div className="app-container">
      
      <Hero />
{/* 
      <h2 className="">Nos Prestations</h2>

      <div className="overflow-hidden">
        <div className="flex gap-x-4 items-start flex-nowrap overflow-x-auto scrollbar-hide scroll-snap-type-x mandatory">
          {prestations.map((item, index) => (
            <Prestation key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            />
          ))}
        </div>
      </div>

      <div className="text-center">
        <a href="/entreprise" className="big btn">Afficher les prestations</a>
      </div> */}

      
    </div>
  );
}

export default Home;


// <2vd-VCp@y8nRA!Q>
// 