import React from "react";

function Shows() {

  return (
    <div className="app-container">
      <h1 className="text-right">Spectacles</h1>

    </div>
  );
}

export default Shows;