import React , { useEffect } from "react";
import '../styles/about.css';
import { Link } from "react-router-dom";


function About() {

  useEffect (() => {
    document.title = "Qui sommes-nous ? | Impro Café";
  }, []);

  return (
    <div className="app-container pt-12">
      <h1 className="text-left">Qui sommes-nous ?</h1>
      <div className="sm:w-9/12 mb-12">
        <p>Nous sommes deux passionnés d’improvisation, et avec Impro Café nous souhaitons apporter une expertise unique et une énergie communicative, qui rendra chaque session interactive et mémorable.</p>
        <p>Notre expérience variée et notre engagement à partager l’art de l’improvisation garantissent des ateliers enrichissants et adaptés à tous vos besoins.</p>
        <p>Vous souhaitez nous connaitre un peu plus ?</p>
      </div>


      <div className="member jimmy">
        <img src="images/about-jimmy.png" alt="Jimmy HARDY" className="about-illustration"/>

        <div className="citation relative">
          <h2>Jimmy HARDY</h2>
          <div className="px-8 py-8 h-fit bg-pure-white rounded-xl">
            <p>Bonjour, je suis Jimmy et je suis passionné d’improvisation depuis plus de dix ans.</p>

            <p>À 32 ans, j’ai eu le privilège de coacher une dizaine de groupes d’improvisateurs âgés de 7 à 75 ans. Mon expérience dépasse le cadre associatif, puisque j’ interviens également dans les écoles, les collèges et les structures d’accueil pour personnes en situation de handicap. </p>

            <p className="p-0">Mon objectif avec Impro Café, est de créer des expériences enrichissantes et inclusives pour tous, en utilisant l’improvisation comme outil de développement personnel et professionnel.</p>
          </div>
        <img src="images/about-jimmy-speak.png" alt="A" className="speak jimmy"/>
        </div>
      </div>


      <div className="member gaby">

        <div className="citation relative">

          <h2 className="text-right">Gabriel CONRAIRIE</h2>
          <div className="px-8 py-8 h-fit bg-pure-white rounded-xl">
            <p>Bonjour, je suis Gabriel, improvisateur depuis près de 15 ans at coach depuis 2019.</p>
            <p>À 45 ans, j’ai également une riche expérience en tant que musicien et illustrateur, domaines dans lesquels l’improvisation est essentielle. Ma passion pour cette discipline m’a conduit à vouloir partager l’euphorie de l’instant présent avec le plus grand nombre.</p>
            <p>Mon objectif avec Impro Café est de créer des ateliers dynamiques et inspirants qui permettent à chacun de découvrir et de savourer les bienfaits de l’improvisation.</p>
          </div>

          <img src="images/about-gaby-speak.png" alt="A" className="speak gaby"/>
        </div>
        <img src="images/about-gaby.png" alt="Gabriel CONRAIRIE" className="about-illustration"/>
      </div>

    <div className="call-to-action">
      <h3 className="font-lilita text-3xl text-coffee pb-8">Vous souhaitez en savoir plus ?</h3>
      <Link to="/contact" className="btn py-4 px-16">Contactez-nous</Link>

    </div>



    </div>
  );
}

export default About;