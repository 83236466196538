import React, {useState, useEffect} from 'react';

interface ObfuscatorProps {
  type: 'mail' | 'tel'; // Can i define the type as 'email' | 'phone'
  value: string;
  className?: string;
}

const Obfuscator:React.FC<ObfuscatorProps> = ({ type, value, className }) => {
  const faker = type === 'mail' ? 'email@domain.com' : '06.00.00.00.00'
  const [ displayedValue, setDisplayedValue ] = useState(faker);
  const [ displayedHref, setDisplayedHref ] = useState('');
  const [ obfuscated, setObfuscated ] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayedHref(type === 'mail' ? `mailto:${value}` : `tel:${value}`);
      setDisplayedValue(value);
      setObfuscated(false);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted
  }, [displayedValue, value, displayedHref, type]);

  return (
      obfuscated ? 
        <span className={` ${className} blur-sm`}>{displayedValue}</span>
        :
        <a href={displayedHref} className={` ${className}`} >{displayedValue}</a>
  );
}

export default Obfuscator;