import React, { createContext, useState, useContext, useCallback } from 'react';

type FlashType = 'success' | 'error' | 'info' | 'warning';

interface FlashMessage {
  type: FlashType;
  message: string;
  id: number;
}

interface FlashContextType {
  messages: FlashMessage[];
  addMessage: (type: FlashType, message: string) => void;
  removeMessage: (id: number) => void;
}

const FlashContext = createContext<FlashContextType | undefined>(undefined);

export const FlashProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<FlashMessage[]>([]);

  const addMessage = useCallback((type: FlashType, message: string) => {
    setMessages(prev => [...prev, { type, message, id: Date.now() }]);
  }, []);

  const removeMessage = useCallback((id: number) => {
    setMessages(prev => prev.filter(message => message.id !== id));
  }, []);

  return (
    <FlashContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </FlashContext.Provider>
  );
};

export const useFlash = () => {
  const context = useContext(FlashContext);
  if (context === undefined) {
    throw new Error('useFlash must be used within a FlashProvider');
  }
  return context;
};