import React, { useState, useEffect } from "react";
import '../styles/contact.css';
import Input from "../component/Form/Input";
import Textarea from "../component/Form/Textarea";
import { useNavigate } from "react-router-dom";
import Loader from "../component/Utils/Loader";
import { useFlash } from "../utils/FlashContext";
import emailjs from 'emailjs-com';

function Contact() {
  const { addMessage } = useFlash();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    zipcode: "",
    message: ""

  });
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    zipcode: "",
    message: ""
  });


  useEffect (() => {
    document.title = "Contact | Impro Café";
  }, []);

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if( e.target.value !== "") {
      setError({
        ...error,
        [e.target.name]: ""
      });
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if( e.target.value !== "") {
      setError({
        ...error,
        message: ""
      });
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validForm()) {
      setLoading(true);


      // EmailJS
    emailjs.send(
      'service_w25eumh',
      'template_eg0y5so',
      form,
      'suXBkS2Kc6kIqpZB6'
    ).then((result) => {
      if( result.text === 'OK' ) {
        addMessage('success', 'Votre email à bien été envoyé');
        navigate('/', { replace: true });
        setLoading(false);
      }
    }, (error) => {
      addMessage('warning', 'Une erreur est survenue lors de l\'envoi de l\'email');
      setLoading(false);
    });
    }
  }

  const handleClearForm = () => {
    setForm({
      name: "",
      email: "",
      phone: "",
      company: "",
      zipcode: "",
      message: ""
    });
    setError({
      name: "",
      email: "",
      phone: "",
      company: "",
      zipcode: "",
      message: ""
    });
  }

  const fakeSend = () => {
    setLoading(true);
    setTimeout(() => {
      addMessage("success", "Votre message a bien été envoyé");
      // navigate('/');
      setLoading(false);
    }, 2000);
  }

  const validForm = () => {

    // Honeypot first
    if( form.zipcode !== "" ) {
      setLoading(true);
      setTimeout(() => {
          addMessage("warning", "Votre message n'a pas pu être envoyé");
          navigate('/');
          setLoading(false);
      },2350);
      return false;
    }
    let valid = true;
    let newError = {
      name: "",
      email: "",
      phone: "",
      company: "",
      zipcode: "",
      message: ""
    }

    if (form.name === "") {
      newError.name = "Veuillez renseigner vos nom et prénom";
      valid = false;
    }

    if (form.email === "") {
      newError.email = "Veuillez renseigner votre adresse e-mail";
      valid = false;
    }

    if( !form.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ) {
      newError.email = "Veuillez renseigner une adresse e-mail valide";
      valid = false;
    }

    if (form.phone === "") {
      newError.phone = "Veuillez renseigner votre numéro de téléphone";
      valid = false;
    }

    if( !form.phone.match(/^0[1-9]([-. ]?[0-9]{2}){4}$/) ) {
      newError.phone = "Veuillez renseigner un numéro de téléphone valide";
      valid = false;
    }

    if (form.message === "") {
      newError.message = "Veuillez renseigner votre message";
      valid = false;
    }

    setError(newError);
    return valid;
  }
  
  
  return (
    <div className="app-container mb-12">

      { loading && <Loader message="Envoi en cours..." /> }
{/* 
    <div className="text-center">
      <button onClick={fakeSend} className="btn px-4 py-2 m-8">Simulation envoi</button>
    </div> */}

      <div className="flex gap-x-8 items-center mb-8">
        <img src="images/contact.png" alt="contactez impro café" className="contact-illustration"/>

        <div className="block-text flex-1">    
            <h1 className="text-right">Contact</h1>
            <p>
            Vous cherchez à renforcer la <b>cohésion</b> de vos collaborateurs, à stimuler leur <b>créativité</b> ou à améliorer leur <b>communication</b> ?
            </p>
            <p>L'improvisation théâtrale est un outil puissant pour atteindre ces objectifs. Que ce soit pour une séance de découverte, un atelier ponctuel ou un programme sur mesure, nous sommes là pour concevoir l'expérience qui transformera votre équipe.</p>
            <p>
            Laissez-nous vos coordonnées et quelques détails sur votre projet. Nous vous recontacterons rapidement pour échanger et vous proposer une intervention adaptée à vos besoins.
            </p>
        </div>
      </div>


      {/* FORMULAIRE */}
      <div className="max-w-[930px] mx-auto">
        <form action="" onSubmit={handleSubmit}>

          <Input 
            label="Nom et prénom*"
            name="name" 
            type="text" 
            value={form.name} 
            error={error.name}
            handleChange={handleChange} 
            className='form-input'
          />

          <Input 
            label="Société"
            name="company" 
            type="text" 
            value={form.company} 
            error={error.company}
            handleChange={handleChange} 
            className='form-input'
          />

          <Input 
            label="Téléphone*"
            name="phone" 
            type="text" 
            value={form.phone} 
            error={error.phone}
            placeholder="06 00 00 00 00"
            handleChange={handleChange} 
            className='form-input'
          />

          <Input 
            label="Adresse e-mail*"
            name="email" 
            type="text" 
            value={form.email} 
            error={error.email}
            placeholder="email@domaine.com"
            handleChange={handleChange} 
            className='form-input'
          />

          <Input 
            label="Adresse"
            name="zipcode" 
            type="text" 
            value={form.zipcode} 
            error={error.zipcode}
            placeholder="Votre code postal"
            handleChange={handleChange} 
            className="hidden"
          />

          <Textarea
            label="Message*"
            name="message"
            value={form.message}
            error={error.message}
            handleMessage={handleMessage}
            className="col-span-2"
          />

          <div className="form-buttons">            
            <button className="btn btn-secondary px-6 py-2 mt-4" type="button" onClick={handleClearForm}>Supprimer</button>
            <button className="btn px-6 py-2 mt-4">Envoyer</button>
          </div>

        </form>
      </div>


          








    </div>
  );
}

export default Contact;