import React, { useEffect, useState  } from 'react';
import { useFlash } from '../../utils/FlashContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface FlashMessageProps {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  id: number;
}

const FlashMessage: React.FC<FlashMessageProps> = ({ message, type, id }) => {
  const { removeMessage } = useFlash();
  const [ opacity, setOpacity ] = useState(0);

  const hideMessage = () => {
    setOpacity(0);
    setTimeout(() => {
      removeMessage(id);
    }, 300);
  };

  useEffect(() => {
    setOpacity(1);
    const timer = setTimeout(() => {
      setOpacity(0);
      setTimeout(() => {
        removeMessage(id);
      }, 300);
    }, 3000); // Remove message after 5 seconds

    return () => clearTimeout(timer);
  }, [id, removeMessage, setOpacity]);

  const bgColor = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-blue-500',
    warning: 'bg-yellow-500',
  }[type];

  return (
    <div className={`${bgColor} text-white py-3 px-4 rounded-md mb-2 flex items-start gap-2 mx-6 transition-all duration-300 opacity-${opacity}`}>
      {message}
      <button onClick={hideMessage}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default FlashMessage;