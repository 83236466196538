import React from 'react';
import { useFlash } from '../../utils/FlashContext';
import FlashMessage from './FlashMessage';

const FlashContainer: React.FC = () => {
  const { messages } = useFlash();

  return (
    <div className="fixed top-6 right-0 z-50">
      {messages.map(message => (
        <FlashMessage
          key={message.id}
          id={message.id}
          type={message.type}
          message={message.message}
        />
      ))}
    </div>
  );
};

export default FlashContainer;