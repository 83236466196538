import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavigationProps {
    className?: string;
    callBack?: () => void;
}


const Navigation:React.FC<NavigationProps> = ({className, callBack }) => {
    const location = useLocation();

    return (
        <div className={className}>
            <Link to="/" className={`${location.pathname === '/' ? 'active-menu' : ''} menu-item`} onClick={callBack}>Accueil</Link>
            {/* <Link to="/entreprise" className={`${location.pathname === '/entreprise' ? 'active-menu' : ''} menu-item`} onClick={callBack}>Entreprise</Link> */}
            {/* <Link to="/ateliers" className={`${location.pathname === '/ateliers' ? 'active-menu' : ''} menu-item`} onClick={callBack}>Ateliers</Link> */}
            {/* <Link to="/spectacles" className={`${location.pathname === '/spectacles' ? 'active-menu' : ''} menu-item`} onClick={callBack}>Spectacles</Link> */}
            <Link to="/about" className={`${location.pathname === '/about' ? 'active-menu' : ''} menu-item`} onClick={callBack}>About nous</Link>
            <Link to="/contact" className={`btn py-2 px-6 ${location.pathname === '/contact' ? '!bg-yellow !text-coffee' : ''} menu-item`} onClick={callBack}>Contact</Link>
        </div>
    );
}

export default Navigation;
