import React from "react";
import "../../styles/hero.css";
import { Link } from "react-router-dom";


function Hero() {
  return (
    <div className="hero">

      <div className="illustration left">
        <img src="images/jimmy.png" alt="Jimmy"/>
      </div>

      <div className="illustration right">
        <img src="images/gaby.png" alt="Gaby"/>
      </div>

      <div className="text">
          <h1>Transformez votre Équipe avec l'Improvisation Théâtrale</h1>
          <h3>Boostez la Cohésion, la Confiance et la Créativité au Sein de Votre Entreprise</h3>
      </div>

      <Link to="/contact" className="btn big mb-10 md:mb-32">contactez-nous</Link>
      
    </div>
  );
}

export default Hero;