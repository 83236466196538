import React from "react";
import "../styles/footer.css";
import Obfuscator from "./Utils/Obfuscator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";


function Footer() {
  return (
    <footer>
        <div className="app-container">

            <div className="group">

                {/* LOGO & Infos */}
                <div className="flex gap-x-4">
                    <img src="images/logo-dark.png" alt="impro café" />
                    <div className="">
                        <p className="font-lilita text-xl">Impro café</p>
                        <p className="text-sm">
                            1 Rue Béole 09000 Mazères<br/>
                            Tel: <Obfuscator type="tel" value="+33(0)6.58.00.87.65" className="link" /><br/>
                            Mail: <Obfuscator type="mail" value="contact@improcafe.fr" className="link" />
                        </p>
                    </div>
                </div>

                {/* Social icons */}
                <div className="flex gap-x-4">
                    {/* <a href="#facebook" className="social-icon" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="#instagram" className="social-icon" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a> */}
                    <a href="https://www.linkedin.com/company/impro-cafe" className="social-icon" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </div>

            </div>

            {/* Credits */}
            <div className="credits">
                <p>2024 © Copyright All Rights Reserved</p>
                <p>Un site web <a href="https://agantar.com" target="_blank" className="agantar" rel="noreferrer">AGANTAR</a></p>
            </div>
        </div>
    </footer>
  );
}

export default Footer;