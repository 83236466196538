import React from "react";
import '../../styles/contact.css';

interface InputProps {
    type: string;
    label: string,
    name: string;
    value: string;
    error: string;
    className?: string;
    placeholder?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ type, label, name, placeholder, value, error, className, handleChange }) => {
  return (
    <div className={`form-group ${className} ${error && 'error'}`}>
        <label htmlFor="name">{label}</label>
        <input
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
        />
        { error && <div className="error-text">{error}</div> }
    </div>

  );
}

export default Input;