import React from "react";

function Entreprise() {

  return (
    <div className="app-container">
      <h1 className="text-right">Entreprise</h1>

    </div>
  );
}

export default Entreprise;