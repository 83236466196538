import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FlashProvider } from './utils/FlashContext';
import FlashContainer from './component/Flash/FlashContainer';
import NotFound from './component/NotFound';
import Contact from './view/Contact';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './view/Home';
import About from './view/About';
import Entreprise from './view/Entreprise';
import Shows from './view/Shows';

// import TailwindCss from './component/Utils/TailwindCss';

function App() {
  return (
    <FlashProvider>
      <FlashContainer />
      <div id="app" className="flex flex-col h-full relative">
        {/* <TailwindCss position='bottom-0 right-0' /> */}
        
        <Header />
      
        <main className='flex-1'>
          <Routes>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="entreprise" element={<Entreprise />} />
            <Route path="spectacles" element={<Shows />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </FlashProvider>
  );
}

export default App;
