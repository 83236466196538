import React from 'react';
import '../styles/header.css';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';


function Header() {

  const toggleMenu = () => {
    document.querySelector('header')?.classList.toggle('open')
    toggleBodyScroll(document.querySelector('header')?.classList.contains('open'))
  }

  const closeMenu = () => {
    document.querySelector('header')?.classList.remove('open')
    toggleBodyScroll(document.querySelector('header')?.classList.contains('open'))
  }

  function toggleBodyScroll(disable: boolean | undefined) {
    document.body.style.overflow = disable ? 'hidden' : '';
  }

  return (
    <header className="app-container min-h-20">

        <Link to='/' className='logo-container'>
            <img src="images/logo.png" alt="Impro café" className='logo'/>
        </Link>

        <nav>          
            <div className="mobile-nav">
              <button className="menu-toggle" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <Navigation className="mobile-menu" callBack={closeMenu} />
            </div>

            <Navigation className="desktop-nav flex items-center py-4"/>
        </nav>
    </header>
  );
}

export default Header;