import React from "react";
import '../../styles/contact.css';

interface TextareaProps {
    label: string,
    name: string;
    value: string;
    error: string;
    className?: string;
  handleMessage: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({ label, name, value, error, className, handleMessage }) => {
  return (
    <div className={`form-group ${className} ${error && 'error'}`}>
        <label htmlFor="name">{label}</label>
          <textarea name="message" value={value} placeholder="Votre message" onChange={ e => handleMessage(e) }></textarea>
        { error && <div className="error-text">{error}</div> }
    </div>

  );
}

export default Textarea;