function NotFound() {
    return (
        <div className="app-container pt-24 text-center">
            <h1>404</h1>
            <p className="p-4 w-fit rounded-lg mx-auto border-2 border-coffee ">La page que vous cherchez n'existe pas.<br/>
            On aurait pu en improviser une, mais pour le coup ça va être un peu compliqué...</p>
        </div>
    );
    }

export default NotFound;